@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Muli:wght@400;700;800;900&display=swap);
.place-funnels-area {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.card-funnel {
    width: 340px;
    margin-right: 15px;
    height: 257px;
    margin-bottom: 30px;
    border-radius: 16px;
    margin-left: 15px;
}

.card-funnel:hover {
    box-shadow: 0 0 9px #26242424;
}

.card-funnel-add {
    border: 1px dashed #05C1FC;
}
.card-funnel-add.based {
    border-color: #6563ff;
}

.place-buttons-card {
    height: 153px;
    border: 1px solid #EFF3F8;
    opacity: 1;
    position: relative;
}

.place-buttons-card:hover {
    background: #2C3E50 0% 0% no-repeat padding-box;
    opacity: 0.65;
}

.place-buttons-card svg {
    display: none;
    color: white;
    width: 15px;
    height: 15px;
    box-shadow: 1px 1px 5px #0000004D;
    opacity: 1;
}

.place-buttons-card svg[data-icon="sitemap"] {
    width: 30px;
    height: 24px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.place-buttons-card svg[data-icon="upload"] {
    width: 30px;
    height: 30px;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
}

.place-buttons-card .sidebar-icons {
    float: right;
    margin-right: 15px;
}

.place-buttons-card .sidebar-icons svg {
    margin-top: 15px;
}

.place-buttons-card:hover svg {
    display: block;
    cursor: pointer;
}

.place-img-first-funnel {
    max-width: 688px;
    margin: 0 auto;
}

.img-first-funnel {
    max-width: 586px;
}

.close-popup {
    right: 0 !important;
    top: 0 !important;
}

.modal-content,
.modal-dialog {
    background-color: #F3F7FF;
    border-radius: 5px;
    min-width: 670px;
}

.title-modal {
    position: relative;
}

.tooltip-inner {
    background: #333;
    color: white;
    font-size: 10px;
}

.tooltip .arrow {
    display: none;
}

.card-funnel-add svg {
    width: 50px !important;
    height: 50px;
}

.card-funnel-add h5 {
    font-size: 20px;
    color: #0294FF;
}
.card-funnel-add.based h5 {
    color: #6563ff;
}

.place-footer-card {
    height: 130px;
    z-index: 0;
    background: white;
}

.place-footer-card a {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #EFF2F8;
}

.place-img-card {
    height: 153px;
    position: absolute;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-success {
    background-color: #01D4B9 !important;
    color: #F8FAFD !important;
    border: none;
    box-shadow: 0.2px 0.8px 2px #1e120d57;
}

.btn-upper {
    padding: 14px 30px;
    text-transform: uppercase;
    color: #F8FAFD;
    box-shadow: 0.2px 0.8px 2px #1e120d57;
    font-weight: 700;
}

.button-first-funnel {
    box-shadow: 0.2px 0.8px 2px #1e120d57;
    text-transform: uppercase;
    font-size: 13px;
    padding: 14px 30px;
    font-weight: 700;
}

.separator {
    padding-top: 10px;
    display: block;
}

.form-control {
    margin-top: 10px;
}

.filters-area {
    margin-top: 70px;
    margin-bottom: 70px;
}

.filters-area svg {
    position: absolute;
    top: 10px;
    right: 10px;
}

.filters-area input,
.filters-area select {
    float: right;
}


.modal-share .modal-content {
    padding: 50px;
}

.modal-share .close {
    position: relative;
}

.modal-share .modal-header,
.modal-share .modal-footer {
    border: none !important;
}

.spacing-50 {
    padding-top: 14px;
    padding-bottom: 14px;
}

.select-countries__single-value {
    margin-left: 50px;
}

.select-countries__value-container,
.select-countries__option {
    text-align: center;
    cursor: pointer !important;
}

.select-countries__control {
    border: none !important;
}

#root {
    overflow: auto;
}

.btn.btn-blue {
    background: #0080FC;
    position: relative;
    padding-left: 30px;
}

.btn.btn-blue > span {
    color: white;
}

.btn.btn-blue > svg {
    left: 10px;
}

.card-funnel picture {
    height: 130px;
    overflow: hidden;
    border-radius: 16px 16px 0 0;
}

.card-funnel picture img{
    width: 100%;
    vertical-align: middle;
    border-style: none;
    position: unset;
}

.card-funnel h5 {
    color: #54779E;
}

.card-funnel .d-flex {
    position: absolute;
    bottom: 1rem;
    padding: 0 1rem;
    align-self: flex-end;
}

.card-funnel > div > a, .card-funnel .dropdown > a {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.card-funnel a:hover {
    text-decoration: none;
    color: #8E9EB5;
}

.card-funnel svg {
    margin-left: 8px;
    margin-top: 7px;
    color: #8E9EB5;
}

.card-funnel .dropdown-menu {
    border-radius: 14px;
    border-left: 1px solid #E4EAF6;
    background: #FFF;
    box-shadow: 6px 6px 13px 0px rgba(0, 0, 0, 0.16);
    inset: unset !important;
    transform: translate(-210px, 5px) !important;
}

builderall-icon{
    --builderall-icon-size: 20px;
    width: 20px;
    height: 16px;
}

[code="fbba"] {
    margin-top: 6px;
    margin-left: 4px;
}

[code="edit"] {
    margin-left: -7px;
}
.container-fluid {
    width: 100%;
    /* height: calc(100% - 50px); */
    height: calc(108% - 0px);  
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.wrapper {
    display: flex;
    width: 100%;
    height: calc(100% - 10px);
    align-items: stretch;
}

.rotateY {
    transition: transform 0.6s;
    transform: rotateY(360deg);
}

.center-button {
    position: absolute;
    top: 50%;
    left: calc(50% - 100px);
    z-index: 2;
}

.center-button label {
    color: #F8FAFD;
    margin-bottom: 0;
    margin-left: 10px;
}

.btn-center-button {
    min-width: 200px;
    font-size: 20px;
}

.react-flow__controls {
    left: 30px !important;
    bottom: 30px !important;
    box-shadow: none !important;
}

.react-flow__controls-button {
    width: 35px !important;
    height: 35px !important;
    margin-bottom: 5px;
    opacity: 0.8;
}

.page-element {
    position: relative;
}

.second-page {
    position: absolute;
    top: 15px;
    left: 15px;
}

.sortable-pages svg {
    color: #666666 !important;
    font-size: 15px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    cursor: move !important;
    padding: 0 !important;
    height: auto !important;
    border: none !important;
}

.sortable-pages svg.fa-arrows-alt-v {
    color: #007bff !important;
}

.sortable-pages .form-group {
    padding-top: 30px;
}

.place-analysis {
    min-width: 800px;
}

.place-analysis .modal-content {
    padding: 50px;
}

.place-analysis .close {
    position: relative;
}

.place-analysis .buttonsWithIcons svg,
.place-analysis .buttonsWithIcons button {
    margin-right: 5px;
}

.large-box {
    height: 193px;
    padding: 10px;
    margin: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    width: 265px;

    text-align: left;
    font: 18px/14px Muli;
    letter-spacing: 0px;
    color: #333333;
}

.first-line .large-box {
    width: 360px;
    height: 134px;
}

.first-line .large-box small {
    margin-top: 7px;
    display: block;
}

.second-line .large-box small {
    margin-top: 7px;
    margin-bottom: 15px;
    display: block;
}

.second-line .large-box:last-of-type {
    width: 170px;
}

.large-box span {
    padding-top: 7px;
}

.large-box .icon-results {
    width: 30px;
    height: 30px;
    text-align: center;
    margin-right: 5px;
    padding-top: 7px;
}

.first-line .icon-results {
    background: transparent linear-gradient(33deg, #0294FF 0%, #00FDC4 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.second-line .icon-results {
    background: transparent linear-gradient(33deg, #FD1A7A 0%, #F67D34 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.large-box svg {
    color: white;
    font-size: 15px !important;
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
    border: none !important;
}

#sidebar-analysis {
    background: #E9EDF2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #26242424;
    border: 1px solid #E3E8EB;
    opacity: 1;
    padding-top: 25px;
}


#sidebar-analysis .place-pill {
    background: #FAFBFC 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding-top: 20px;
    padding-bottom: 20px;
}

#sidebar-analysis .place-pill {
    padding-left: 20px;
    padding-right: 20px;
}
#sidebar-analysis .place-elements-of-analysis svg {
    border: none;
    margin-top: 0;
    margin-top: initial;
    height: auto;
    height: initial;
}
#sidebar-analysis .place-elements-of-analysis .form-control {
    margin-right: 5px;
    margin-top: 0;
}

#sidebar-analysis .place-pill label {
    text-align: left;
    font: 18px/24px Muli;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

#sidebar-analysis .place-pill .form-group.product,
#sidebar-analysis .place-pill label.w-25 {
    text-align: left;
    font: 13px/17px Lato;
    letter-spacing: 0px;
    color: #434343;
    opacity: 0.8;
    margin-left: 5px;
}

#sidebar-analysis .button-save {
    position: absolute;
    top: 50px;
    right: 35px;
}

#sidebar-analysis .nav-tabs {
    padding: 25px;
}

#sidebar-analysis .nav-tabs,
#sidebar-analysis .nav-tabs .nav-link {
    background-color: transparent !important;
    border: none !important;
    min-width: 111px;
}

#sidebar-analysis .nav-tabs .nav-link {
    text-align: left;
    font: Bold 20px/26px Muli;
    letter-spacing: 0px;
    color: #434343;
    opacity: 0.3;
}

#sidebar-analysis .nav-tabs .nav-link.active {
    opacity: 1;
}

.place-elements-of-analysis {
    margin-left: -30px;
    margin-right: -30px;
    background-color: white;
    padding: 30px;
    border-bottom: 1px solid #4343433b;
    height: calc(100% - 500px);
    position: relative;
    overflow: auto;
}

.divisor {
    border-bottom: 1px solid #d4d4d4;
    margin: 15px -30px;
}

.roi-area svg {
    font-size: 17px !important;
    margin-left: 5px !important;
    color: green;
}

.roi-area svg.fa.long-arrow-alt-down {
    color: red;
}

.w-10 {
    width: 10% !important;
}

.w-18 {
    width: 18% !important;
}
.w-33 {
    width: 33% !important;
}

.p-clients {
    margin-top: 15px;
    text-align: left;
    font-weight: 700;
}

.redo-tour {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.helper-tag {
    display: block;
    width: 100% !important;
    text-align: left;
    padding: 20px;
    border-radius: 5px;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    margin-bottom: 15px;
}
.flow-sub-element,
.flow-element:not(.external-element) {
    background-color: white;
}

.flow-sub-element,
.popup-element,
.checkout-element,
.form-element,
.page-element {
    width: 150px;
    height: 180px;
    border-radius: 5px;
    border: 1px solid #DEDEDE;
}

.flow-sub-element,
.checkout-element,
.popup-element,
.form-element {
    height: auto;
}

.react-flow__node-subElementNode .flow-sub-element {
    width: 148px;
}

.react-flow__node-restrictedAreaNode .title-elem,
.flow-sub-element .title-elem {
    text-align: left;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: #5A5959;
    max-height: 100px;
    overflow: hidden;
}

.timer-tag {
    position: absolute;
    top: -20px;
    font-size: 13px;
    color: #5A5959;
}

.timer-tag svg {
    margin-right: 5px;
}

.automation-element {
    width: 200px;
}

.automation-config {
    text-align: left;
    padding: 15px;
    font: 10px Lato;
    color: #5A5959;
    opacity: 0.6;
    padding-top: 0;
}

.ddcontainer {
    position: absolute;
    width: 100%;
    height: 100%;
}

.react-flow__controls-button {
    width: 24px;
    height: 24px;
}

/*PageNode CSS*/
.react-flow__node.selected {
    box-shadow: 0 0 9px #26242416;
    border-radius: 5px;
    border: 0.5px solid #348bff;
}

.border-website {
    height: 5px;
    border-radius: 4px 4px 0 0;
}

.image-page {
    height: 100px;
    background-color: #c8deff61;
}

.image-page img {
    max-height: 100px;
    max-width: 130px;
}

.title-page {
    color: #5A5959;
    opacity: 1;
    font-weight: 700;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

/*ExternalNode CSS*/
.external-element img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.user-img-element img {
    border-radius: 0;
    border-radius: initial;
}

.btn-add-new-pages {
    position: absolute;
    height: 15px;
    line-height: 0.5;
    padding: 2.5px;
    width: 15px;
    font-size: 13px;
    top: 30%;
    right: -8px;
    border-radius: 50%;
    background-color: #a4c9fe;
}

.btn-add-new-pages svg {
    width: 9px !important;
    height: 9px;
    vertical-align: 0;
}

.flow-sub-element h6 {
    text-align: center;
    padding: 5px;
    height: auto;
}

.flow-sub-element .btn-add-new-pages {
    top: 15%;
}


.react-flow__handle {
    background-color: white !important;
    height: 15px !important;
    width: 15px !important;
    border-radius: 50%;
    border: 1px solid #a4c9fe;
}

.flow-sub-element .react-flow__handle-right {
    top: 70%;
}

/*rest*/

.restricted-area-element {
    width: 150px;
    height: 103px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 3px #26242429;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    opacity: 1;
}

.place-rounded-circle {
    position: absolute;
}

.restricted-area-circle {
    width: 25px;
    height: 25px;
    background-color: blue;
}

#idEditorContent {
    width: 500px;
    height: 300px;
    margin: 0 auto;
}

.react-flow__edges {
    z-index: 3 !important;
}

.react-flow__handle-top {
    top: -8px !important;
}

.react-flow__handle-bottom {
    bottom: -8px !important;
}

.react-flow__handle-right {
    right: -8px !important;
}

.react-flow__handle-left {
    left: -8px !important;
}

.react-flow__edge-path {
    stroke: #a4c9ff !important;
}

.selected .react-flow__edge-path {
    stroke: #0067ff !important;
    marker-end: url("#hover-arrow")
}

.deleteArea {
    display: none;
}

.selected .deleteArea {
    display: inline;
    display: initial;
}

.circle-div {
    border-radius: 50%;
    background-image: url('http://pic.1fotonin.com/data/wallpapers/1/WDF_499177.jpg');
    width: 25px;
    height: 25px;
    background-size: cover;
    background-position: 25% 25%;
    position: absolute;
    left: 7.5px;
    top: 2.5px;
}

.removeIcon {
    position: absolute;
    bottom: -6px;
    width: 15px;
    left: calc(50% - 8px);
    z-index: 2000;
    height: 15px;
    cursor: pointer;
    background-image: url(/static/removeIconBG.svg);
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 50%;
    background-position: center;
    background-size: contain;
    display: none;
}

.selected .removeIcon {
    display: inline;
    display: initial;
}

.counter-page {
    position: absolute;
    bottom: -25px;
}

.react-flow__edge-textbg {
    fill: #a4c9ff !important;
}

[data-tour-elem="left-arrow"] {
    display: none !important;
}

[data-tour-elem="right-arrow"] {
    width: 100%;
}

[data-tour-elem="right-arrow"] button {
    float: right;
}

.home-icon {
    position: absolute;
    left: -10px;
    top: -10px;
    background-color: #a4c9fd;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 11px;
    margin: 0 auto;
    text-align: center;
    padding-top: 2px;
}

.external-element .title-elem,
.user-img-element .title-elem{
    text-align: left;
    padding: 5px;
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: #5A5959;
    max-height: 100px;
    overflow: hidden;
    max-width: 100px;
}

.alert-icon{
    position: absolute;
    right: 15px;
    top: -10px;
    background-color: #ff0000;
    border-radius: 50%;
    padding: 4px;
    width: 20px !important;
    height: 20px;
    color: white;
}
.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.place-pages-modal {
    background: #F8FAFD 0% 0% no-repeat padding-box;
}

.place-pages-modal .category-navbar {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E3E8EB;
    opacity: 1;
}

.place-pages-modal .tab-blue {
    background: #2690F7 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    border-radius: 5px;
    color: white;
    position: relative;
}

.place-pages-modal .tab-blue:after {
    display: inline-block;
    width: 0;
    position: absolute;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    right: 10px;
    top: 17px;
}

.place-pages-modal .tab-blue[aria-selected="true"]:after {
    border-top: 0;
    border-bottom: .3em solid;
}

.place-pages-modal .tab-blue.active {
    border-radius: 5px 5px 0 0;
}

.place-pages-modal .tab-content {
    overflow: auto;
    max-height: 520px;
    height: 520px;
}

.place-pages-modal .col-sm-9 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E3E8EB;
    border-radius: 5px;
    opacity: 1;
    padding-right: 0;
}

#addPagePopup .modal-header {
    padding: 0 !important;
    padding-bottom: 30px !important;
}

#addPagePopup .modal-dialog {
    background: #F8FAFD 0% 0% no-repeat padding-box;
    box-shadow: 0px 14px 32px #00000040;
    border-radius: 10px;
    opacity: 1;
}

.page-add,
.user-img-element-add,
.external-element-add {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.user-img-element-add,
.external-element-add {
    width: 100px;
    height: 100px;
    border-radius: 50%;

}

.user-img-element-add {
    border-radius: 0;
    border-radius: initial;
    margin-right: 50px;
}

.user-img-element {
    position: relative;
}

.user-img-element svg.fa-trash-alt {
    display: none;
    padding: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid red;
    right: -10px;
    top: -5px;
    position: absolute;
    color: red;
    background: white;
    cursor: pointer;
}

.user-img-element:hover svg.fa-trash-alt {
    display: block;
}

.page-add {
    width: 150px;
    height: 200px;
    border: 1px solid #EFF3F8;
    border-radius: 5px;
    opacity: 1;
    position: relative;
    margin-right: 30px;
}

.page-add .plus-button {
    background-color: #047ffc;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 23px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    position: absolute;
    left: calc(50% - 25px);
    top: calc(100% - 75px);

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.page-add:hover .plus-button {
    visibility: visible;
    opacity: 1;
}

.page-add:hover .plus-button:hover {
    box-shadow: 0 0 9px #26242424;
}

#addPagePopup .modal-content {
    border-left: 1px solid #047ffc;
    box-shadow: 0 0 9px #00000033;
}

.image-add {
    width: 132px;
    height: 132px;
}

.template-add {
    width: 211px;
    height: 132px;
    border-radius: 5px;
    opacity: 1;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #e9edf1;
    margin-bottom: 10px;
}

.template-add:hover {
    border: 3px solid #0080FC;
}

#addPagePopup .modal-content,
#addPagePopup .modal-dialog {
    border-radius: 5px;
    min-width: 1140px;
    max-height: 850px;
    width: 1140px;
}


@media screen and (max-width: 1140px) {
    #addPagePopup .modal-content,
    #addPagePopup .modal-dialog {
        min-width: 800px;
        width: 800px;
    }
}


#addPagePopup .modal-content {
    padding: 50px;
}

#addPagePopup .modal-content,
#addPagePopup .modal-header {
    border: none !important;
    background: #f7fafd 0% 0% no-repeat padding-box;
}

#addPagePopup .restricted-area-button {
    padding-top: 30px;
    padding-bottom: 50px;
}

#addPagePopup .col-sm-9 {
    padding: 30px;
}

#addPagePopup .desc-page {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.title-available-pages:not(:first-of-type) {
    margin-top: 20px;
}

.title-available-pages h5 {
    font-size: 17px !important;
}


.category-click-bar {
    position: absolute;
    top: 90px;
    left: -156px;
    width: 155px;
    text-align: right;
    overflow-x: hidden;
    max-height: 435px;
    overflow-y: auto;
}

.category-click-bar .category-navbar {
    background-color: white;
    cursor: pointer;
    display: inline-block;
    max-width: 155px;
    text-align: center;
    margin-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 3px 0 0 3px;
    font-size: 15px;
    font-weight: bold;
    font-family: Muli, sans-serif;
    color: #434343;
    opacity: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.category-click-bar .category-navbar:hover {
    background: #0080FC 0% 0% no-repeat padding-box;
    box-shadow: 0 0 9px #00000033;
    border: 0.5px solid #7070704D;
    opacity: 1;
    color: white;
}

.place-pages-modal .fade {
    display: none;
}

.place-pages-modal .fade.show {
    display: block;
}
.sidebar-analysis,
.sidebar-canvas {
    min-width: 400px;
    max-width: 400px;
    min-height: calc(100vh - 60px);
    display: none;
}

.sidebar-analysis.active,
.sidebar-canvas.active {
    display: block;
}

.sidebar-canvas .sidebar-brand {
    background: #0080FC17 0% 0% no-repeat padding-box;
    border: 1px solid #DEDEDE;
    opacity: 1;
    padding: 30px;
}

.sidebar-canvas .sidebar-brand .dropdown-toggle {
    padding: 12px 30px;
    font-size: 15px;
    font-weight: 700;
}

.modal-campaign .nav-tabs,
.sidebar-canvas .nav-tabs {
    padding-left: 15px;
    border-bottom: 1px solid #0080FC;
    margin-bottom: 30px;
}

.sidebar-analysis .tab-content,
.sidebar-canvas .tab-content {
    padding-left: 30px;
    padding-right: 30px;
}

.sidebar-analysis .tab-content .tab-pane #forecasting-edition,
.sidebar-analysis .tab-content .tab-pane,
.sidebar-analysis .tab-content {
    height: 100%;
}

.sidebar-canvas .tab-content label {
    font-family: Lato, sans-serif;
    margin-bottom: 20px;
}

.modal-campaign .nav-tabs a,
.modal-campaign .nav-tabs a:hover,
.modal-campaign .nav-tabs a.active,
.sidebar-canvas .nav-tabs a,
.sidebar-canvas .nav-tabs a:hover,
.sidebar-canvas .nav-tabs a.active {
    border: 0 !important;

    text-transform: uppercase;
    padding-bottom: 0;
    text-align: left;
    font: Bold 13px/26px Muli;
    letter-spacing: 0px;
    color: #0080FC;
    opacity: 0.5;
}

.modal-campaign  .nav-tabs a.active,
.sidebar-canvas .nav-tabs a.active {
    border-bottom: 3px solid blue !important;
    text-align: left;
    font: Bold 13px/26px Muli;
    letter-spacing: 0;
    color: #0080FC;
    opacity: 1;
}

.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.popup-element-add:not(:last-of-type) {
    border-bottom: 1px solid #DEDEDE;
    opacity: 1;
    margin-bottom: 30px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
}

.popup-element-add h5 {
    text-align: left;
    font: Bold 16px/17px Muli;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
}

.popup-element-add button {
    background: #5A5959 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 9px #26242429;
    border-radius: 5px;
    opacity: 1;
    min-width: 176px;
    min-height: 36px;
}


#sidebar-edition-element .place-btn {
    text-align: right;
}

.swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
}

.popover {
    position: absolute;
    z-index: 2
}

.swatch-color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.image-place-checkout img,
.image-place-checkout input {
    width: 100%;
    max-width: 266px;
}

.modal-90w .close {
    position: relative;
}

.modal-90w,
.modal-90w .modal-content {
    min-width: 1140px;
}

.modal-90w .modal-content {
    padding: 50px;
}

.big-sidebar {
    min-width: 700px
}

.sidebar-right-absolute {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 1000;
    box-shadow: 0 0 9px #26242424;
}

.sidebar-right-absolute .close {
    top: 15px !important;
    right: 15px !important;
}

#element-edition-restricted,
.title-page-sidebar {
    padding: 30px;
}
.tab-content,
.title-page-sidebar.no-top {
    overflow-y: auto;
    max-height: calc(100% - 250px);
    min-height: 500px;
}

.sidebar-analysis .tab-content{
    overflow-y: visible;
    overflow-y: initial;
    max-height: none;
    max-height: initial;
    min-height: 0;
    min-height: initial;
}

.line-bottom {
    border-bottom: 1px solid #a5c9fe;
}

.btn-lists {
    margin-bottom: 20px;
    background-color: #6C757D !important;
    border: #6C757D !important;
}

.btn-lists:hover {
    background-color: #596065 !important;
    border: #596065 !important;
}

.btn-create-campaign {
    margin-top: 10px;
}

#element-edition-restricted > label {
    font-size: 14px;
}

#element-edition-restricted > label > div {
    margin-top: 10px;
}

#element-edition-restricted .form-check-label {
    font-size: 16px;
}

#element-edition-restricted > label,
.modal-campaign .modal-body > label {
    margin-bottom: 20px;
}

.modal-campaign .modal-content {
    padding: 50px;
}

.modal-campaign .close {
    position: relative;
}

.modal-campaign .modal-header,
.modal-campaign .modal-footer {
    border: none !important;
}

.no-top {
    padding-top: 0;
}

.place-page-test svg {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.place-page-test .place-btn-remove svg {
    position: absolute;
    right: -25px;
}

.place-page-test {
    position: relative;
    width: 90%;
}

.place-btn-remove-all {
    border-top: 1px solid #dedede;
    padding-top: 30px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
}

.image-popup {
    cursor: pointer;
    border-radius: 5px;
}

.image-popup.active {
    box-shadow: 0px 2px 10px #0000001A;
    border: 2px solid #2690F7;
}

.place-popup-img {
    margin-bottom: 30px;
}

.automation-edition-area {
    overflow-y: auto;
    height: calc(100% - 85px);
    overflow-x: hidden;
    padding-right: 30px;
    margin: 30px 0;
    padding-left: 30px;
}

.template-item-select {
    margin-right: 10px;
    height: 50px;
    width: 90px;
    display: inline-flex;
    background-position: center;
    background-size: contain;
}

.react-select-templates__single-value .template-item-select{
    display: none;
}

.place-templates-body{
    background-color: white;
}
.place-templates-body .nav-tabs{
    margin-left: -15px;
    margin-right: -15px;
}


.line{
    border-top: 1px solid transparent;
    margin-bottom: 20px;
    margin-top: 20px;
}

.line.color{
    border-top: 1px solid lightgray;
}

.margin-top-10{
    margin-top: 10px !important;
}
* label {
    color: #434343;
}

body {
    font-family: 'Roboto', sans-serif;
    color: #111;
}

html {
    overflow: hidden;
}

html,
body {
    margin: 0;
}

html,
body,
#root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    background-color: #e9edf1;
}

header {
    padding: 10px;
    border-bottom: 1px solid #eee;
    font-weight: 700;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 9px #26242424;
    min-height: 60px;

}

header .dropdown-toggle.btn.btn-primary {
    text-transform: uppercase;
    border-radius: 50%;
    background-color: #047ffc;
    border-color: #047ffc;
    width: 44px;
    height: 44px;
}

header .dropdown-toggle.btn.btn-primary::after {
    content: none;
}

header .name-header.navbar-brand .logo {
    max-height: 36px;
}

header .name-header.navbar-brand {
    float: left;
    width: 630px;
    display: flex;
}

header .place-icons {
}

header .place-icons svg {
    cursor: pointer;
    padding-right: 15px;
    padding-left: 15px;
    border-right: 1px solid #DEDEDE;
    margin-top: -10px;
    height: 60px;
    font-size: 53px;
}

header .place-icons svg:hover {
    color: #047ffc;
}

header .place-icons svg.fa-sitemap,
header .place-icons svg.fa-chart-pie {
    border-left: 1px solid #DEDEDE;
}

header .place-icons svg.fa-sitemap,
header .place-icons svg.fa-project-diagram {
    right: 0;
    font-size: 45px;
}

header .btn-upper {
    margin-top: -3px;
}

.title-obj {
    font-size: 23px;
    font-weight: lighter;
    color: #000;
}

.btn-settings {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    background-color: #0080fc;
    color: #fff;
    text-transform: uppercase;
}

.user-dropdown-menu {
    float: right;
}

.btn-settings:after {
    display: none;
}

.navbar-brand {
    padding: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.hidden {
    display: none !important;
}

.btn-info {
    background-color: #0080FC;
    border-color: #0080FC;
}

.btn-info:hover {
    background-color: #0061c2;
    border-color: #0061c2;
}

.btn-link {
    color: #5A5959;
}

.btn-link:hover {
    text-decoration: none;
}

* {
    font-family: Muli, sans-serif;
    letter-spacing: 0;
}

label {
    font-family: Lato, sans-serif;
}

h1 {
    font-size: 31px;
    font-weight: 700;
    color: #0080FC;
    opacity: 1;
}


h2 {
    font-size: 20px;
    font-weight: 500;
    color: #5A748B;
    opacity: 1;
}

h3 {
    font-size: 20px;
    font-weight: 700;
    color: #434343;
    opacity: 1;
}

h4 {
    font-size: 16px;
    font-weight: 700;
    color: #434343;
    opacity: 0.8;
}

h5 {
    font-family: Lato, sans-serif;
    font-size: 15px;
    color: #0080FC;
    opacity: 1;
    font-weight: 700;
}

.modal {
    text-align: center;
    background-color: #00000030;
}

@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

img {
    pointer-events: none;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.float-right .btn-upper {
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: none;
    height: 45px;
}

.modal-padding .modal-header,
.modal-padding .modal-footer {
    border: none !important;
}

.modal-padding .modal-content {
    padding: 50px;
}

.modal-padding .close {
    position: relative;
}

.marginTopSpacing {
    margin-top: 50px;
}

.card-push {
    margin-top: 20px;
    margin-bottom: 20px;
    opacity: 1;
}

.card-push .card-header {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #7E7E7E10;
    border-radius: 5px;

    text-align: left;
    font: normal normal bold 20px/35px Muli;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    opacity: 0.64;
}

.card-push .card-header svg {
    position: absolute;
    right: 20px;
    top: 18px;
    color: #0080FC;
}

.card-push .card-body {
    display: flex;
    padding-left: 5px;
}

.card-push .card-body .card-page {
    background: #FAFBFC 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    opacity: 1;

    width: 247px;
    height: 46px;

    text-align: left;
    font: normal normal 14px/35px Lato;
    letter-spacing: 0px;
    color: #656565;
    padding: 5px;

    margin-left: 15px;
    margin-right: 5px;
}

.card-push .card-body .button {
    background: #0080FC 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px #1E120D29;
    border-radius: 3px;
    opacity: 1;
}


.btn-sync-page{
    position: absolute;
    right: 10px;
    top: 4px;
}

.tooltip-inner{
    max-width: 250px !important;
    font-size: 15px !important;
    background: #0080FC;
    font-family: Muli, sans-serif;
}
