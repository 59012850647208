.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.place-pages-modal {
    background: #F8FAFD 0% 0% no-repeat padding-box;
}

.place-pages-modal .category-navbar {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E3E8EB;
    opacity: 1;
}

.place-pages-modal .tab-blue {
    background: #2690F7 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    border-radius: 5px;
    color: white;
    position: relative;
}

.place-pages-modal .tab-blue:after {
    display: inline-block;
    width: 0;
    position: absolute;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    right: 10px;
    top: 17px;
}

.place-pages-modal .tab-blue[aria-selected="true"]:after {
    border-top: 0;
    border-bottom: .3em solid;
}

.place-pages-modal .tab-blue.active {
    border-radius: 5px 5px 0 0;
}

.place-pages-modal .tab-content {
    overflow: auto;
    max-height: 520px;
    height: 520px;
}

.place-pages-modal .col-sm-9 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E3E8EB;
    border-radius: 5px;
    opacity: 1;
    padding-right: 0;
}

#addPagePopup .modal-header {
    padding: 0 !important;
    padding-bottom: 30px !important;
}

#addPagePopup .modal-dialog {
    background: #F8FAFD 0% 0% no-repeat padding-box;
    box-shadow: 0px 14px 32px #00000040;
    border-radius: 10px;
    opacity: 1;
}

.page-add,
.user-img-element-add,
.external-element-add {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.user-img-element-add,
.external-element-add {
    width: 100px;
    height: 100px;
    border-radius: 50%;

}

.user-img-element-add {
    border-radius: initial;
    margin-right: 50px;
}

.user-img-element {
    position: relative;
}

.user-img-element svg.fa-trash-alt {
    display: none;
    padding: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid red;
    right: -10px;
    top: -5px;
    position: absolute;
    color: red;
    background: white;
    cursor: pointer;
}

.user-img-element:hover svg.fa-trash-alt {
    display: block;
}

.page-add {
    width: 150px;
    height: 200px;
    border: 1px solid #EFF3F8;
    border-radius: 5px;
    opacity: 1;
    position: relative;
    margin-right: 30px;
}

.page-add .plus-button {
    background-color: #047ffc;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 23px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    position: absolute;
    left: calc(50% - 25px);
    top: calc(100% - 75px);

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.page-add:hover .plus-button {
    visibility: visible;
    opacity: 1;
}

.page-add:hover .plus-button:hover {
    box-shadow: 0 0 9px #26242424;
}

#addPagePopup .modal-content {
    border-left: 1px solid #047ffc;
    box-shadow: 0 0 9px #00000033;
}

.image-add {
    width: 132px;
    height: 132px;
}

.template-add {
    width: 211px;
    height: 132px;
    border-radius: 5px;
    opacity: 1;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #e9edf1;
    margin-bottom: 10px;
}

.template-add:hover {
    border: 3px solid #0080FC;
}

#addPagePopup .modal-content,
#addPagePopup .modal-dialog {
    border-radius: 5px;
    min-width: 1140px;
    max-height: 850px;
    width: 1140px;
}


@media screen and (max-width: 1140px) {
    #addPagePopup .modal-content,
    #addPagePopup .modal-dialog {
        min-width: 800px;
        width: 800px;
    }
}


#addPagePopup .modal-content {
    padding: 50px;
}

#addPagePopup .modal-content,
#addPagePopup .modal-header {
    border: none !important;
    background: #f7fafd 0% 0% no-repeat padding-box;
}

#addPagePopup .restricted-area-button {
    padding-top: 30px;
    padding-bottom: 50px;
}

#addPagePopup .col-sm-9 {
    padding: 30px;
}

#addPagePopup .desc-page {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.title-available-pages:not(:first-of-type) {
    margin-top: 20px;
}

.title-available-pages h5 {
    font-size: 17px !important;
}


.category-click-bar {
    position: absolute;
    top: 90px;
    left: -156px;
    width: 155px;
    text-align: right;
    overflow-x: hidden;
    max-height: 435px;
    overflow-y: auto;
}

.category-click-bar .category-navbar {
    background-color: white;
    cursor: pointer;
    display: inline-block;
    max-width: 155px;
    text-align: center;
    margin-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 3px 0 0 3px;
    font-size: 15px;
    font-weight: bold;
    font-family: Muli, sans-serif;
    color: #434343;
    opacity: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.category-click-bar .category-navbar:hover {
    background: #0080FC 0% 0% no-repeat padding-box;
    box-shadow: 0 0 9px #00000033;
    border: 0.5px solid #7070704D;
    opacity: 1;
    color: white;
}

.place-pages-modal .fade {
    display: none;
}

.place-pages-modal .fade.show {
    display: block;
}