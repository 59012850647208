@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Muli:wght@400;700;800;900&display=swap');

* label {
    color: #434343;
}

body {
    font-family: 'Roboto', sans-serif;
    color: #111;
}

html {
    overflow: hidden;
}

html,
body {
    margin: 0;
}

html,
body,
#root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    background-color: #e9edf1;
}

header {
    padding: 10px;
    border-bottom: 1px solid #eee;
    font-weight: 700;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 9px #26242424;
    min-height: 60px;

}

header .dropdown-toggle.btn.btn-primary {
    text-transform: uppercase;
    border-radius: 50%;
    background-color: #047ffc;
    border-color: #047ffc;
    width: 44px;
    height: 44px;
}

header .dropdown-toggle.btn.btn-primary::after {
    content: none;
}

header .name-header.navbar-brand .logo {
    max-height: 36px;
}

header .name-header.navbar-brand {
    float: left;
    width: 630px;
    display: flex;
}

header .place-icons {
}

header .place-icons svg {
    cursor: pointer;
    padding-right: 15px;
    padding-left: 15px;
    border-right: 1px solid #DEDEDE;
    margin-top: -10px;
    height: 60px;
    font-size: 53px;
}

header .place-icons svg:hover {
    color: #047ffc;
}

header .place-icons svg.fa-sitemap,
header .place-icons svg.fa-chart-pie {
    border-left: 1px solid #DEDEDE;
}

header .place-icons svg.fa-sitemap,
header .place-icons svg.fa-project-diagram {
    right: 0;
    font-size: 45px;
}

header .btn-upper {
    margin-top: -3px;
}

.title-obj {
    font-size: 23px;
    font-weight: lighter;
    color: #000;
}

.btn-settings {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    background-color: #0080fc;
    color: #fff;
    text-transform: uppercase;
}

.user-dropdown-menu {
    float: right;
}

.btn-settings:after {
    display: none;
}

.navbar-brand {
    padding: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.hidden {
    display: none !important;
}

.btn-info {
    background-color: #0080FC;
    border-color: #0080FC;
}

.btn-info:hover {
    background-color: #0061c2;
    border-color: #0061c2;
}

.btn-link {
    color: #5A5959;
}

.btn-link:hover {
    text-decoration: none;
}

* {
    font-family: Muli, sans-serif;
    letter-spacing: 0;
}

label {
    font-family: Lato, sans-serif;
}

h1 {
    font-size: 31px;
    font-weight: 700;
    color: #0080FC;
    opacity: 1;
}


h2 {
    font-size: 20px;
    font-weight: 500;
    color: #5A748B;
    opacity: 1;
}

h3 {
    font-size: 20px;
    font-weight: 700;
    color: #434343;
    opacity: 1;
}

h4 {
    font-size: 16px;
    font-weight: 700;
    color: #434343;
    opacity: 0.8;
}

h5 {
    font-family: Lato, sans-serif;
    font-size: 15px;
    color: #0080FC;
    opacity: 1;
    font-weight: 700;
}

.modal {
    text-align: center;
    background-color: #00000030;
}

@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

img {
    pointer-events: none;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.float-right .btn-upper {
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: none;
    height: 45px;
}

.modal-padding .modal-header,
.modal-padding .modal-footer {
    border: none !important;
}

.modal-padding .modal-content {
    padding: 50px;
}

.modal-padding .close {
    position: relative;
}

.marginTopSpacing {
    margin-top: 50px;
}

.card-push {
    margin-top: 20px;
    margin-bottom: 20px;
    opacity: 1;
}

.card-push .card-header {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #7E7E7E10;
    border-radius: 5px;

    text-align: left;
    font: normal normal bold 20px/35px Muli;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    opacity: 0.64;
}

.card-push .card-header svg {
    position: absolute;
    right: 20px;
    top: 18px;
    color: #0080FC;
}

.card-push .card-body {
    display: flex;
    padding-left: 5px;
}

.card-push .card-body .card-page {
    background: #FAFBFC 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    opacity: 1;

    width: 247px;
    height: 46px;

    text-align: left;
    font: normal normal 14px/35px Lato;
    letter-spacing: 0px;
    color: #656565;
    padding: 5px;

    margin-left: 15px;
    margin-right: 5px;
}

.card-push .card-body .button {
    background: #0080FC 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px #1E120D29;
    border-radius: 3px;
    opacity: 1;
}


.btn-sync-page{
    position: absolute;
    right: 10px;
    top: 4px;
}

.tooltip-inner{
    max-width: 250px !important;
    font-size: 15px !important;
    background: #0080FC;
    font-family: Muli, sans-serif;
}