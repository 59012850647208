.sidebar-analysis,
.sidebar-canvas {
    min-width: 400px;
    max-width: 400px;
    min-height: calc(100vh - 60px);
    display: none;
}

.sidebar-analysis.active,
.sidebar-canvas.active {
    display: block;
}

.sidebar-canvas .sidebar-brand {
    background: #0080FC17 0% 0% no-repeat padding-box;
    border: 1px solid #DEDEDE;
    opacity: 1;
    padding: 30px;
}

.sidebar-canvas .sidebar-brand .dropdown-toggle {
    padding: 12px 30px;
    font-size: 15px;
    font-weight: 700;
}

.modal-campaign .nav-tabs,
.sidebar-canvas .nav-tabs {
    padding-left: 15px;
    border-bottom: 1px solid #0080FC;
    margin-bottom: 30px;
}

.sidebar-analysis .tab-content,
.sidebar-canvas .tab-content {
    padding-left: 30px;
    padding-right: 30px;
}

.sidebar-analysis .tab-content .tab-pane #forecasting-edition,
.sidebar-analysis .tab-content .tab-pane,
.sidebar-analysis .tab-content {
    height: 100%;
}

.sidebar-canvas .tab-content label {
    font-family: Lato, sans-serif;
    margin-bottom: 20px;
}

.modal-campaign .nav-tabs a,
.modal-campaign .nav-tabs a:hover,
.modal-campaign .nav-tabs a.active,
.sidebar-canvas .nav-tabs a,
.sidebar-canvas .nav-tabs a:hover,
.sidebar-canvas .nav-tabs a.active {
    border: 0 !important;

    text-transform: uppercase;
    padding-bottom: 0;
    text-align: left;
    font: Bold 13px/26px Muli;
    letter-spacing: 0px;
    color: #0080FC;
    opacity: 0.5;
}

.modal-campaign  .nav-tabs a.active,
.sidebar-canvas .nav-tabs a.active {
    border-bottom: 3px solid blue !important;
    text-align: left;
    font: Bold 13px/26px Muli;
    letter-spacing: 0;
    color: #0080FC;
    opacity: 1;
}

.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.popup-element-add:not(:last-of-type) {
    border-bottom: 1px solid #DEDEDE;
    opacity: 1;
    margin-bottom: 30px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
}

.popup-element-add h5 {
    text-align: left;
    font: Bold 16px/17px Muli;
    letter-spacing: 0px;
    color: #434343;
    opacity: 1;
}

.popup-element-add button {
    background: #5A5959 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 9px #26242429;
    border-radius: 5px;
    opacity: 1;
    min-width: 176px;
    min-height: 36px;
}


#sidebar-edition-element .place-btn {
    text-align: right;
}

.swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
}

.popover {
    position: absolute;
    z-index: 2
}

.swatch-color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.image-place-checkout img,
.image-place-checkout input {
    width: 100%;
    max-width: 266px;
}

.modal-90w .close {
    position: relative;
}

.modal-90w,
.modal-90w .modal-content {
    min-width: 1140px;
}

.modal-90w .modal-content {
    padding: 50px;
}

.big-sidebar {
    min-width: 700px
}

.sidebar-right-absolute {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 1000;
    box-shadow: 0 0 9px #26242424;
}

.sidebar-right-absolute .close {
    top: 15px !important;
    right: 15px !important;
}

#element-edition-restricted,
.title-page-sidebar {
    padding: 30px;
}
.tab-content,
.title-page-sidebar.no-top {
    overflow-y: auto;
    max-height: calc(100% - 250px);
    min-height: 500px;
}

.sidebar-analysis .tab-content{
    overflow-y: initial;
    max-height: initial;
    min-height: initial;
}

.line-bottom {
    border-bottom: 1px solid #a5c9fe;
}

.btn-lists {
    margin-bottom: 20px;
    background-color: #6C757D !important;
    border: #6C757D !important;
}

.btn-lists:hover {
    background-color: #596065 !important;
    border: #596065 !important;
}

.btn-create-campaign {
    margin-top: 10px;
}

#element-edition-restricted > label {
    font-size: 14px;
}

#element-edition-restricted > label > div {
    margin-top: 10px;
}

#element-edition-restricted .form-check-label {
    font-size: 16px;
}

#element-edition-restricted > label,
.modal-campaign .modal-body > label {
    margin-bottom: 20px;
}

.modal-campaign .modal-content {
    padding: 50px;
}

.modal-campaign .close {
    position: relative;
}

.modal-campaign .modal-header,
.modal-campaign .modal-footer {
    border: none !important;
}

.no-top {
    padding-top: 0;
}

.place-page-test svg {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.place-page-test .place-btn-remove svg {
    position: absolute;
    right: -25px;
}

.place-page-test {
    position: relative;
    width: 90%;
}

.place-btn-remove-all {
    border-top: 1px solid #dedede;
    padding-top: 30px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
}

.image-popup {
    cursor: pointer;
    border-radius: 5px;
}

.image-popup.active {
    box-shadow: 0px 2px 10px #0000001A;
    border: 2px solid #2690F7;
}

.place-popup-img {
    margin-bottom: 30px;
}

.automation-edition-area {
    overflow-y: auto;
    height: calc(100% - 85px);
    overflow-x: hidden;
    padding-right: 30px;
    margin: 30px 0;
    padding-left: 30px;
}

.template-item-select {
    margin-right: 10px;
    height: 50px;
    width: 90px;
    display: inline-flex;
    background-position: center;
    background-size: contain;
}

.react-select-templates__single-value .template-item-select{
    display: none;
}

.place-templates-body{
    background-color: white;
}
.place-templates-body .nav-tabs{
    margin-left: -15px;
    margin-right: -15px;
}


.line{
    border-top: 1px solid transparent;
    margin-bottom: 20px;
    margin-top: 20px;
}

.line.color{
    border-top: 1px solid lightgray;
}

.margin-top-10{
    margin-top: 10px !important;
}