.place-funnels-area {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.card-funnel {
    width: 340px;
    margin-right: 15px;
    height: 257px;
    margin-bottom: 30px;
    border-radius: 16px;
    margin-left: 15px;
}

.card-funnel:hover {
    box-shadow: 0 0 9px #26242424;
}

.card-funnel-add {
    border: 1px dashed #05C1FC;
}
.card-funnel-add.based {
    border-color: #6563ff;
}

.place-buttons-card {
    height: 153px;
    border: 1px solid #EFF3F8;
    opacity: 1;
    position: relative;
}

.place-buttons-card:hover {
    background: #2C3E50 0% 0% no-repeat padding-box;
    opacity: 0.65;
}

.place-buttons-card svg {
    display: none;
    color: white;
    width: 15px;
    height: 15px;
    box-shadow: 1px 1px 5px #0000004D;
    opacity: 1;
}

.place-buttons-card svg[data-icon="sitemap"] {
    width: 30px;
    height: 24px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.place-buttons-card svg[data-icon="upload"] {
    width: 30px;
    height: 30px;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
}

.place-buttons-card .sidebar-icons {
    float: right;
    margin-right: 15px;
}

.place-buttons-card .sidebar-icons svg {
    margin-top: 15px;
}

.place-buttons-card:hover svg {
    display: block;
    cursor: pointer;
}

.place-img-first-funnel {
    max-width: 688px;
    margin: 0 auto;
}

.img-first-funnel {
    max-width: 586px;
}

.close-popup {
    right: 0 !important;
    top: 0 !important;
}

.modal-content,
.modal-dialog {
    background-color: #F3F7FF;
    border-radius: 5px;
    min-width: 670px;
}

.title-modal {
    position: relative;
}

.tooltip-inner {
    background: #333;
    color: white;
    font-size: 10px;
}

.tooltip .arrow {
    display: none;
}

.card-funnel-add svg {
    width: 50px !important;
    height: 50px;
}

.card-funnel-add h5 {
    font-size: 20px;
    color: #0294FF;
}
.card-funnel-add.based h5 {
    color: #6563ff;
}

.place-footer-card {
    height: 130px;
    z-index: 0;
    background: white;
}

.place-footer-card a {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #EFF2F8;
}

.place-img-card {
    height: 153px;
    position: absolute;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-success {
    background-color: #01D4B9 !important;
    color: #F8FAFD !important;
    border: none;
    box-shadow: 0.2px 0.8px 2px #1e120d57;
}

.btn-upper {
    padding: 14px 30px;
    text-transform: uppercase;
    color: #F8FAFD;
    box-shadow: 0.2px 0.8px 2px #1e120d57;
    font-weight: 700;
}

.button-first-funnel {
    box-shadow: 0.2px 0.8px 2px #1e120d57;
    text-transform: uppercase;
    font-size: 13px;
    padding: 14px 30px;
    font-weight: 700;
}

.separator {
    padding-top: 10px;
    display: block;
}

.form-control {
    margin-top: 10px;
}

.filters-area {
    margin-top: 70px;
    margin-bottom: 70px;
}

.filters-area svg {
    position: absolute;
    top: 10px;
    right: 10px;
}

.filters-area input,
.filters-area select {
    float: right;
}


.modal-share .modal-content {
    padding: 50px;
}

.modal-share .close {
    position: relative;
}

.modal-share .modal-header,
.modal-share .modal-footer {
    border: none !important;
}

.spacing-50 {
    padding-top: 14px;
    padding-bottom: 14px;
}

.select-countries__single-value {
    margin-left: 50px;
}

.select-countries__value-container,
.select-countries__option {
    text-align: center;
    cursor: pointer !important;
}

.select-countries__control {
    border: none !important;
}

#root {
    overflow: auto;
}

.btn.btn-blue {
    background: #0080FC;
    position: relative;
    padding-left: 30px;
}

.btn.btn-blue > span {
    color: white;
}

.btn.btn-blue > svg {
    left: 10px;
}

.card-funnel picture {
    height: 130px;
    overflow: hidden;
    border-radius: 16px 16px 0 0;
}

.card-funnel picture img{
    width: 100%;
    vertical-align: middle;
    border-style: none;
    position: unset;
}

.card-funnel h5 {
    color: #54779E;
}

.card-funnel .d-flex {
    position: absolute;
    bottom: 1rem;
    padding: 0 1rem;
    align-self: flex-end;
}

.card-funnel > div > a, .card-funnel .dropdown > a {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.card-funnel a:hover {
    text-decoration: none;
    color: #8E9EB5;
}

.card-funnel svg {
    margin-left: 8px;
    margin-top: 7px;
    color: #8E9EB5;
}

.card-funnel .dropdown-menu {
    border-radius: 14px;
    border-left: 1px solid #E4EAF6;
    background: #FFF;
    box-shadow: 6px 6px 13px 0px rgba(0, 0, 0, 0.16);
    inset: unset !important;
    transform: translate(-210px, 5px) !important;
}

builderall-icon{
    --builderall-icon-size: 20px;
    width: 20px;
    height: 16px;
}

[code="fbba"] {
    margin-top: 6px;
    margin-left: 4px;
}

[code="edit"] {
    margin-left: -7px;
}