.flow-sub-element,
.flow-element:not(.external-element) {
    background-color: white;
}

.flow-sub-element,
.popup-element,
.checkout-element,
.form-element,
.page-element {
    width: 150px;
    height: 180px;
    border-radius: 5px;
    border: 1px solid #DEDEDE;
}

.flow-sub-element,
.checkout-element,
.popup-element,
.form-element {
    height: auto;
}

.react-flow__node-subElementNode .flow-sub-element {
    width: 148px;
}

.react-flow__node-restrictedAreaNode .title-elem,
.flow-sub-element .title-elem {
    text-align: left;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: #5A5959;
    max-height: 100px;
    overflow: hidden;
}

.timer-tag {
    position: absolute;
    top: -20px;
    font-size: 13px;
    color: #5A5959;
}

.timer-tag svg {
    margin-right: 5px;
}

.automation-element {
    width: 200px;
}

.automation-config {
    text-align: left;
    padding: 15px;
    font: 10px Lato;
    color: #5A5959;
    opacity: 0.6;
    padding-top: 0;
}

.ddcontainer {
    position: absolute;
    width: 100%;
    height: 100%;
}

.react-flow__controls-button {
    width: 24px;
    height: 24px;
}

/*PageNode CSS*/
.react-flow__node.selected {
    box-shadow: 0 0 9px #26242416;
    border-radius: 5px;
    border: 0.5px solid #348bff;
}

.border-website {
    height: 5px;
    border-radius: 4px 4px 0 0;
}

.image-page {
    height: 100px;
    background-color: #c8deff61;
}

.image-page img {
    max-height: 100px;
    max-width: 130px;
}

.title-page {
    color: #5A5959;
    opacity: 1;
    font-weight: 700;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

/*ExternalNode CSS*/
.external-element img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.user-img-element img {
    border-radius: initial;
}

.btn-add-new-pages {
    position: absolute;
    height: 15px;
    line-height: 0.5;
    padding: 2.5px;
    width: 15px;
    font-size: 13px;
    top: 30%;
    right: -8px;
    border-radius: 50%;
    background-color: #a4c9fe;
}

.btn-add-new-pages svg {
    width: 9px !important;
    height: 9px;
    vertical-align: 0;
}

.flow-sub-element h6 {
    text-align: center;
    padding: 5px;
    height: auto;
}

.flow-sub-element .btn-add-new-pages {
    top: 15%;
}


.react-flow__handle {
    background-color: white !important;
    height: 15px !important;
    width: 15px !important;
    border-radius: 50%;
    border: 1px solid #a4c9fe;
}

.flow-sub-element .react-flow__handle-right {
    top: 70%;
}

/*rest*/

.restricted-area-element {
    width: 150px;
    height: 103px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 3px #26242429;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    opacity: 1;
}

.place-rounded-circle {
    position: absolute;
}

.restricted-area-circle {
    width: 25px;
    height: 25px;
    background-color: blue;
}

#idEditorContent {
    width: 500px;
    height: 300px;
    margin: 0 auto;
}

.react-flow__edges {
    z-index: 3 !important;
}

.react-flow__handle-top {
    top: -8px !important;
}

.react-flow__handle-bottom {
    bottom: -8px !important;
}

.react-flow__handle-right {
    right: -8px !important;
}

.react-flow__handle-left {
    left: -8px !important;
}

.react-flow__edge-path {
    stroke: #a4c9ff !important;
}

.selected .react-flow__edge-path {
    stroke: #0067ff !important;
    marker-end: url("#hover-arrow")
}

.deleteArea {
    display: none;
}

.selected .deleteArea {
    display: initial;
}

.circle-div {
    border-radius: 50%;
    background-image: url('http://pic.1fotonin.com/data/wallpapers/1/WDF_499177.jpg');
    width: 25px;
    height: 25px;
    background-size: cover;
    background-position: 25% 25%;
    position: absolute;
    left: 7.5px;
    top: 2.5px;
}

.removeIcon {
    position: absolute;
    bottom: -6px;
    width: 15px;
    left: calc(50% - 8px);
    z-index: 2000;
    height: 15px;
    cursor: pointer;
    background-image: url(/static/removeIconBG.svg);
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 50%;
    background-position: center;
    background-size: contain;
    display: none;
}

.selected .removeIcon {
    display: initial;
}

.counter-page {
    position: absolute;
    bottom: -25px;
}

.react-flow__edge-textbg {
    fill: #a4c9ff !important;
}

[data-tour-elem="left-arrow"] {
    display: none !important;
}

[data-tour-elem="right-arrow"] {
    width: 100%;
}

[data-tour-elem="right-arrow"] button {
    float: right;
}

.home-icon {
    position: absolute;
    left: -10px;
    top: -10px;
    background-color: #a4c9fd;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 11px;
    margin: 0 auto;
    text-align: center;
    padding-top: 2px;
}

.external-element .title-elem,
.user-img-element .title-elem{
    text-align: left;
    padding: 5px;
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: #5A5959;
    max-height: 100px;
    overflow: hidden;
    max-width: 100px;
}

.alert-icon{
    position: absolute;
    right: 15px;
    top: -10px;
    background-color: #ff0000;
    border-radius: 50%;
    padding: 4px;
    width: 20px !important;
    height: 20px;
    color: white;
}